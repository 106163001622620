//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from 'quasar'
export default {
    name: "Index",
    data(){
        return {
            monthOrCustom: 'month',
            timePop: false,
            timeType: 'start',
            apis: {

            },
            dateTxt: this.$moment().format('yyyy-MM'),
            basicData: {},
            structure: {
                newAndOld: {
                    newNum: 0,
                    oldNum: 0,
                    newMoney: 0,
                    oldMoney: 0,
                    numP: 0,
                    moneyP: 0
                }
            },
            levelData: {},
            categoryData: [],
            categoryDataMore: [],
            thisNumSort: [],
            thisNumSortMore: [],
            thisMoneySort: [],
            thisMoneySortMore: [],
            allNumSort: [],
            allNumSortMore: [],
            allMoneySort: [],
            allMoneySortMore: [],
            allSortBy: 'allNum',
            thisSortBy: 'thisNum',
            currentDate: new Date(this.$moment().format('yyyy-MM-01')),
            currentMonth: new Date(this.$moment().format('yyyy-MM')),
            tempStartDate: this.$moment().format('yyyy-MM-01'),
            tempEndDate: this.$moment().format('yyyy-MM-DD'),
            tempMonth: this.$moment().format('yyyy-MM'),
            maxDate: null,
            minDate: new Date(2004, 1, 1),
            saleLoading: false,
            vipSaleLoading: false,
            categorySaleLoading: false,
            allModelLoading: false,
            thisModelLoading: false,

            stores: [],
            storeOption: [],
            currentStore: '',

            hasMoreCategory: false,
            categoryDataMoreOpen: false,
            allNumSortMoreOpen: false,
            allMoneySortMoreOpen: false,
            thisNumSortMoreOpen: false,
            thisMoneySortMoreOpen: false
        }
    },
    mounted() {
        window.document.title = '门店业绩';
    },
    activated() {
        window.document.title = '门店业绩';
    },
    created() {
        window.document.title = '门店业绩';
        const that = this;
      
        let temp = LocalStorage.getItem('userOwnerShops');
        let shopNames = LocalStorage.getItem('ownerShopNames');
        if (temp && temp!= '' && temp != 'null'){
            this.stores = temp.split(',');
            let storeNames = shopNames.split(',');
            this.currentStore = this.stores[0];
            this.stores.forEach((item, i)=>{
                that.storeOption.push({text: item + ' ' +storeNames[i], value: item});
            });
        }else {
            that.storeOption = [];
        }

        let startDate = this.$moment().format('yyyyMM') + '01';
        let endDate = this.$moment().format('yyyyMMDD');
        this.startDate = startDate;
        this.endDate = endDate;
        this.maxDate = new Date();

        if (this.stores.length > 0){
            this.getAPI(function () {
                that.dispatchApi();
            });
        }
    },
    methods: {
        storeChange(){
            this.dispatchApi();
        },
        reloadData(){
            if (this.monthOrCustom == 'custom'){
                let start = new Date(this.tempStartDate).getTime(),
                    end = new Date(this.tempEndDate).getTime();
                if (start>end){ //需要交换
                    this.startDate = this.$moment(this.tempEndDate).format('yyyyMMDD');
                    this.endDate = this.$moment(this.tempStartDate).format('yyyyMMDD');
                    this.dateTxt = this.tempEndDate + ' 至 ' + this.tempStartDate;
                }else {
                    this.startDate = this.$moment(this.tempStartDate).format('yyyyMMDD');
                    this.endDate = this.$moment(this.tempEndDate).format('yyyyMMDD');
                    this.dateTxt = this.tempStartDate + ' 至 ' + this.tempEndDate;
                }
            }else {
                this.startDate = this.$moment(this.tempMonth).format('yyyyMM01');
                // this.endDate = this.$moment(this.tempMonth).getLast()
                let month = this.tempMonth.split('-')[1];
                if (month == (new Date().getMonth()+1)){
                    this.endDate = this.$moment().format('yyyyMMDD')
                }else {
                    let end = this.$moment(this.tempMonth).endOf('month');
                    this.endDate = this.$moment(end).format('yyyyMMDD');
                }
                this.dateTxt = this.tempMonth;
            }
            this.dispatchApi();
            this.timePop = false;
        },
        timeChange(){
            if (this.monthOrCustom == 'custom'){
                if (this.timeType == 'start'){
                    this.tempStartDate = this.$moment(this.currentDate).format('yyyy-MM-DD');
                }else if (this.timeType == 'end'){
                    this.tempEndDate = this.$moment(this.currentDate).format('yyyy-MM-DD');
                }
            }else {
                if (this.timeType == 'start'){
                    this.tempMonth = this.$moment(this.currentMonth).format('yyyy-MM');
                }
            }
        },
        dispatchApi(){
            const that = this;
            that.getShopSale();
            that.getVipSaleData();
            that.getCategorySaleData();
            that.getAllModelTop();
            that.getThisModelTop();
        },
        getAPI(callback){
            const that = this;
            // this.axios.post('/v1/bw/BwApi/getFunList', {
            this.axios.post('/' + this.$ajaxPrefix.consumer + '/bw/BwApi/getFunList', {
                "queryMap": {
                    "q_status": 1
                }
            }).then(res=>{
                if (res && res.data && res.data.data){
                    const temp = res.data.data;
                    temp.forEach(item=>{
                        that.apis[item.outIdentity] = item.outIdentity//.split('_').join('/');
                    });
                    callback && callback();
                }
            })
        },
        getShopSale(){
            const that = this;
            let startDate = this.startDate;
            let endDate = this.endDate;
            const data = [
                {HIGH: endDate, LOW: startDate, LOW_FLAG: '1', OPTION: 'CP', PARAMETER: 'ZDATE_103', SIGN: 'I'},
                {HIGH: "", LOW: this.currentStore, LOW_FLAG: '1', OPTION: 'EQ', PARAMETER: 'ZV_MD06', SIGN: 'I'}
            ];
            this.saleLoading = true;
            // this.axios.post('/v1/bw/BwApi/' + this.apis['saleAnalysis_getSales'], data).then(res=>{
            this.axios.post('/' + this.$ajaxPrefix.consumer + '/bw/BwApi/' + this.apis['saleAnalysis_getSales'], data).then(res=>{
               if (res && res.data && res.data.data){
                   that.basicData = res.data.data[0];

                   if (that.basicData){
                       let newMoney = Number(Number(that.basicData.newVipSaleMoney).toFixed(0)),
                           oldMoney = Number(Number(that.basicData.oldVipSaleMoney).toFixed(0)),
                           newNum = Number(Number(that.basicData.newVipSaleNum).toFixed(0)),
                           oldNum = Number(Number(that.basicData.oldVipSaleNum).toFixed(0));
                       that.structure.newAndOld = {
                           newMoney,
                           oldMoney,
                           newNum,
                           oldNum,
                           numP: parseInt((newNum/(newNum+oldNum))*100),
                           moneyP: parseInt((newMoney/(newMoney+oldMoney))*100)
                       };
                   }else {
                       that.structure.newAndOld = {
                           newNum: 0,
                           oldNum: 0,
                           newMoney: 0,
                           oldMoney: 0,
                           numP: 0,
                           moneyP: 0
                       }
                   }
               }else {
                   that.structure.newAndOld = {
                       newNum: 0,
                       oldNum: 0,
                       newMoney: 0,
                       oldMoney: 0,
                       numP: 0,
                       moneyP: 0
                   }
               }
               that.saleLoading = false;
            });
        },
        getVipSaleData(){
            const that = this;

            const data = [
                {"HIGH": this.endDate,"LOW": this.startDate, "LOW_FLAG": "1", "OPTION": "BT", "PARAMETER": "ZDATE_001", "SIGN": "I"},
                {"HIGH": "", "LOW": this.currentStore, "LOW_FLAG": "0", "OPTION": "EQ", "PARAMETER": "ZV_KH003", "SIGN": "I"}
            ];
            this.vipSaleLoading = true;
            // this.axios.post('/v1/bw/BwApi/' + this.apis['sales_structure'], data).then(res=>{
            this.axios.post('/' + this.$ajaxPrefix.consumer + '/bw/BwApi/' + this.apis['sales_structure'], data).then(res=>{
                if (res && res.data && res.data.data){
                    const temp = res.data.data;
                    const levels = new Set();
                    const levelData = {};
                    let moneySum = 0;
                    temp.forEach(item=>{
                        levels.add(item.level);
                        moneySum += Number(item.vipMoney);
                    });
                    let levelArr = Array.from(levels);
                    levelArr.forEach(item=>{
                        levelData[item] = {
                            money: 0,
                            peopleCount: 0
                        };
                    });
                    for (let level in levelData){
                        temp.forEach(item=>{
                            if (item.level === level){
                                levelData[level]['money'] += Number(item.vipMoney);
                                levelData[level]['peopleCount'] += 1;
                            }
                        });
                    }
                    for (let level in levelData){
                        levelData[level]['percent'] = Number(levelData[level]['money'] / moneySum * 100).toFixed(2);
                    }
                    that.levelData = levelData;
                }else {
                    that.levelData = {};
                }
                that.vipSaleLoading = false;
            })
        },

        getCategorySaleData(){
            const that = this;
            const data = [
                {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZV_MD06","LOW":this.currentStore,"HIGH":"","LOW_FLAG":"1"},
                {"SIGN":"I","OPTION":"BT","PARAMETER":"ZDATE_001","LOW":this.startDate,"HIGH":this.endDate,"LOW_FLAG":"1"},
                // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZPP_003","LOW":"10","HIGH":"","LOW_FLAG":"1"}
            ];
            this.categorySaleLoading = true;
            // this.axios.post('/v1/bw/BwApi/' + this.apis['QueryReport_queryCategorySalesProportion'], data).then(res=>{
            this.axios.post('/' + this.$ajaxPrefix.consumer + '/bw/BwApi/' + this.apis['QueryReport_queryCategorySalesProportion'], data).then(res=>{
                if (res && res.data && res.data.data){
                    const temp = res.data.data;
                    let cates = new Set();
                    const _temp = [];
                    temp.forEach(item=>{
                        if (item.category != 'SUMME'){
                            cates.add(item.category);
                            _temp.push(item);
                        }
                    });
                    let catesArr = Array.from(cates);
                    const cateData = {};
                    catesArr.forEach(item=>{
                        cateData[item] = {
                            name: '',
                            quantity: 0,
                            quantityP: 0,
                            disAmount: 0,
                            disP: 0,
                            paidAmount: 0,
                            paidP: 0
                        }
                    });
                    for (let key in cateData){
                        _temp.forEach(item=>{
                            if (key === item.category){
                                cateData[key]['name'] = item.categoryName;
                                cateData[key]['quantity'] += Number(item.quantity);
                                cateData[key]['quantityP'] += Number(item.quantityProportion);
                                cateData[key]['disAmount'] += Number(item.discountedAmount);
                                cateData[key]['disP'] += Number(item.discountedAmountProportion);
                                cateData[key]['paidAmount'] += Number(item.paidAmount);
                                cateData[key]['paidP'] += Number(item.paidAmountProportion);
                            }
                        });
                    }
                    let keys = Object.keys(cateData);

                    let count = keys.length;
                    if (count > 10){
                        that.hasMoreCategory = true;
                        let temp1 = {}, temp2 = {};
                        let keys1 = keys.slice(0,10), keys2 = keys.slice(10, count);

                        keys1.forEach(item=>{
                            temp1[item] = cateData[item];
                        });
                        keys2.forEach(item=>{
                            temp2[item] = cateData[item];
                        });
                        that.categoryData = temp1;
                        that.categoryDataMore = temp2;
                    }else {
                        that.categoryData = cateData;
                        that.categoryDataMore = [];
                        that.hasMoreCategory = false;
                    }

                    // that.categoryData = cateData;
                    console.log(cateData);
                    that.$nextTick(function () {
                        setTimeout(()=>{
                            let width = document.getElementById('toGetWidth').offsetWidth;
                            document.getElementById('cate').style.width = width + 'px';
                        }, 200)
                    });
                }else {
                    that.categoryData = [];
                    that.categoryDataMore = [];
                }
                that.categorySaleLoading = false;
            });
        },

        getAllModelTop(){
            const that = this;
            const data = [
                {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZVAR_SALETYPE01","LOW":"P","HIGH":""},
                // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZPP_003","LOW":"10","HIGH":""},
                // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZPP_003","LOW":"11","HIGH":""},
                // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZPP_003","LOW":"12","HIGH":""},
                {"SIGN":"I","OPTION":"BT","PARAMETER":"ZDATE_001","LOW":this.startDate,"HIGH":this.endDate,"LOW_FLAG":"1"},
                // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZV_CPNF07","LOW":this.$moment(this.startDate).format('yyyy'),"HIGH":"","LOW_FLAG":"1"}
            ];
            this.allModelLoading = true;
            // this.axios.post('/v1/bw/BwApi/' + this.apis['top10_getModelsTop10'], data).then(res=>{
            this.axios.post('/' + this.$ajaxPrefix.consumer + '/bw/BwApi/' + this.apis['top10_getModelsTop10'], data).then(res=>{
                //VOL-YD 销量    VAL-YD 销额
                if(res && res.data && res.data.data){
                    let temp = res.data.data;
                    temp.sort((a, b)=>{
                        return Number(b.VOL_YD) - Number(a.VOL_YD);
                    });
                    let allNumSort = temp.length > 50 ? temp.slice(0, 50) : temp;
                    temp.sort((a, b)=>{
                        return Number(b.VAL_YD) - Number(a.VAL_YD);
                    });
                    let allMoneySort = temp.length > 50 ? temp.slice(0, 50) : temp;
                    if (allNumSort.length > 10){
                        that.allNumSort = allNumSort.slice(0, 10);
                        that.allNumSortMore = allNumSort.slice(10, allNumSort.length);
                    }else {
                        that.allNumSort = allNumSort;
                        that.allNumSortMore = [];
                    }

                    if (allMoneySort.length > 10){
                        that.allMoneySort = allMoneySort.slice(0, 10);
                        that.allMoneySortMore = allMoneySort.slice(10, allMoneySort.length);
                    }else {
                        that.allMoneySort = allMoneySort;
                        that.allMoneySortMore = [];
                    }
                }else {
                    that.allMoneySort = [];
                    that.allMoneySortMore = [];
                    that.allNumSort = [];
                    that.allNumSortMore = [];
                }
                that.allModelLoading = false;
            })
        },
        getThisModelTop(){
            const that = this;
            const data = [
                {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZVAR_SALETYPE01","LOW":"P","HIGH":""},
                {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZV_KH001","LOW":this.currentStore,"HIGH":""},
                {"SIGN":"I","OPTION":"BT","PARAMETER":"ZDATE_001","LOW":this.startDate,"HIGH":this.endDate,"LOW_FLAG":"1"},
                // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZV_CPNF07","LOW":this.$moment(this.startDate).format('yyyy'),"HIGH":"","LOW_FLAG":"1"}
            ];
            this.thisModelLoading = true;
            // this.axios.post('/v1/bw/BwApi/' + this.apis['top10_getModelsTop10'], data).then(res=>{
            this.axios.post('/' + this.$ajaxPrefix.consumer + '/bw/BwApi/' + this.apis['top10_getModelsTop10'], data).then(res=>{
                //VOL-YD 销量    VAL-YD 销额
                if(res && res.data && res.data.data){
                    let temp = res.data.data;
                    temp.sort((a, b)=>{
                        return Number(b.VOL_YD) - Number(a.VOL_YD);
                    });
                    let thisNumSort = temp.length > 50 ? temp.slice(0, 50) : temp;
                    temp.sort((a, b)=>{
                        return Number(b.VAL_YD) - Number(a.VAL_YD);
                    });
                    let thisMoneySort = temp.length > 50 ? temp.slice(0, 50) : temp;

                    if (thisMoneySort.length > 10){
                        that.thisMoneySort = thisMoneySort.slice(0, 10);
                        that.thisMoneySortMore = thisMoneySort.slice(10, thisMoneySort.length);
                    }else {
                        that.thisMoneySort = thisMoneySort;
                        that.thisMoneySortMore = [];
                    }

                    if (thisNumSort.length > 10){
                        that.thisNumSort = thisNumSort.slice(0, 10);
                        that.thisNumSortMore = thisNumSort.slice(10, thisNumSort.length);
                    }else {
                        that.thisNumSort = thisNumSort;
                        that.thisNumSortMore = [];
                    }
                }else {
                    that.thisMoneySort = [];
                    that.thisMoneySortMore = [];
                    that.thisNumSort = [];
                    that.thisNumSortMore = [];
                }
                that.thisModelLoading = false;
            })
        }
    }
}
